import React from 'react';

import * as d3 from 'd3';

import { Deployment, Location } from '@wavely/deployment-sdk';
import { Metadatum } from '../types/models';

const svgHeight = 100;
const svgWidth = 1600;
const margin = 20;
const chartHeight = svgHeight - 2 * margin;
const chartWidth = svgWidth - 2 * margin;

interface ChartProps {
  location: Location;
  deployments: Deployment[];
  metadata: Metadatum[];
  startDate?: Date;
  endDate?: Date;
}

const Chart = (props: ChartProps) => {
  const chartId = `chart-${props.location.id}`;
  const chartElementRef = React.useRef<
    d3.Selection<SVGGElement, unknown, HTMLElement, any>
  >();
  const [scale, setScale] = React.useState<d3.ScaleTime<number, number>>(() =>
    d3
      .scaleTime()
      .domain([
        new Date(props.location.startDate!).getTime(),
        new Date().getTime(),
      ])
      .range([0, 1000])
      .clamp(true),
  );
  const axisElementRef = React.useRef<
    d3.Selection<SVGGElement, unknown, HTMLElement, any>
  >();

  React.useEffect(() => {
    const chart = d3
      .select(`#${chartId}`)
      .append('svg')
      .attr('height', svgHeight)
      .attr('width', svgWidth)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`);

    const axisElement = chart
      .append('g')
      .attr('transform', `translate(0, ${chartHeight})`);

    axisElementRef.current = axisElement;
    chartElementRef.current = chart;
  }, [chartId]);

  React.useEffect(() => {
    const newScale = d3
      .scaleTime()
      .domain([
        props.startDate || new Date(props.location.startDate!).getTime(),
        props.endDate || new Date().getTime(),
      ])
      .range([0, chartWidth])
      .clamp(true);

    const newAxis = d3.axisBottom(newScale).ticks(10);
    const axisElement = axisElementRef.current!;
    const t = d3.transition().duration(250);
    axisElement.transition(t as any).call(newAxis);
    setScale(() => newScale);
  }, [props.location.startDate, props.startDate, props.endDate, setScale]);

  React.useEffect(() => {
    const chart = chartElementRef.current!;

    const depElements = chart
      .selectAll<SVGRectElement, Deployment>('.deployment')
      .data(props.deployments, d => `${d.id}`);

    depElements
      .enter()
      .append('rect')
      .attr('class', 'deployment')
      .attr('fill', '#00a6b9')
      .attr('fill-opacity', 0.2)
      .attr('height', chartHeight)
      .merge(depElements as any)
      .transition()
      .duration(250)
      .attr(
        'width',
        d =>
          scale(
            d.endDate ? new Date(d.endDate).getTime() : new Date().getTime(),
          ) - scale(new Date(d.startDate!).getTime()),
      )
      .attr('x', d => scale(new Date(d.startDate!).getTime()));

    depElements.lower();

    const eventElements = chart
      .selectAll<SVGRectElement, Metadatum>('.event')
      .data(props.metadata, d => `${d.id}`);

    eventElements
      .enter()
      .append('a')
      .attr('class', 'event')
      .attr('href', d => `/api/magnetos/${d.sensor}/recordings/${d.id}`)
      .attr('download', '')
      .append('rect')
      .attr('id', ({ id }) => `event-${id.replace('.', '')}`)
      .attr('fill', '#ff0000')
      .attr('height', chartHeight)
      .attr(
        'width',
        d =>
          scale(new Date(d.startTime).getTime() + 10000) -
          scale(new Date(d.startTime).getTime()),
      )
      .attr('x', d => scale(new Date(d.startTime).getTime()))
      .on('mouseover', ({ id }) => {
        const elementId = `#event-${id.replace('.', '')}`;
        chart.select(elementId).attr('fill', '#0F9960');
      })
      .on('mouseout', ({ id }) => {
        const elementId = `#event-${id.replace('.', '')}`;
        chart.select(elementId).attr('fill', '#ff0000');
      })
      .merge(eventElements as any)
      .transition()
      .duration(250)
      .select('rect')
      .attr(
        'width',
        d =>
          scale(new Date(d.startTime).getTime() + 10000) -
          scale(new Date(d.startTime).getTime()),
      )
      .attr('x', d => scale(new Date(d.startTime).getTime()));

    eventElements.exit().remove();

    eventElements.raise();
  }, [props.deployments, props.metadata, scale, props.location.id]);

  return <div id={chartId} className="chart-container" />;
};

export default Chart;

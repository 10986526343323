import React from 'react';

import { Field, FieldProps, Form, Formik, FormikActions } from 'formik';

import { Button, InputGroup, Intent } from '@blueprintjs/core';

import useAuth from '../effects/useAuth';

interface FormValues {
  username: string;
  password: string;
}

const validate = (values: FormValues) => {
  let errors = {};

  if (values.username === '') {
    errors = { ...errors, username: 'Please enter an username' };
  }
  if (values.password === '') {
    errors = { ...errors, password: 'Please enter a password' };
  }

  return errors;
};

interface AuthProps {}

const Auth = (props: AuthProps) => {
  const initialValues: FormValues = {
    username: '',
    password: '',
  };

  const { isAuthenticated, username, login, error } = useAuth();

  const onSubmit = (values: FormValues, actions: FormikActions<FormValues>) => {
    login(values).finally(() => actions.setSubmitting(false));
  };

  if (isAuthenticated) {
    return (
      <Button icon="thumbs-up" intent="success" disabled={true} minimal={true}>
        Logged-in as <b>{username}</b>
      </Button>
    );
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ isSubmitting, errors }) => (
        <Form className="login-form">
          <Field
            name="username"
            autoFocus={true}
            render={({ field }: FieldProps<FormValues>) => (
              <InputGroup
                type="text"
                placeholder="Enter your username..."
                leftIcon="person"
                intent={errors.username ? Intent.DANGER : undefined}
                {...field}
              />
            )}
          />
          <Field
            name="password"
            render={({ field }: FieldProps<FormValues>) => (
              <InputGroup
                type="password"
                placeholder="Enter your password..."
                leftIcon="key"
                intent={errors.password ? Intent.DANGER : undefined}
                {...field}
              />
            )}
          />
          <Button
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            intent={error ? Intent.DANGER : Intent.PRIMARY}
            icon="log-in"
          >
            Sign in
          </Button>
        </Form>
      )}
    />
  );
};

export default Auth;

import {
  DefaultApi as DeploymentApi,
  Configuration,
  Credentials,
} from '@wavely/deployment-sdk';

import axios from './axios';

class DeploymentApiClient extends DeploymentApi {
  public login(credentials: Credentials) {
    return this.obtainTokenPair(credentials).then(res => {
      this.configuration = new Configuration({
        username: credentials.username,
        accessToken: res.data.access,
      });

      return res;
    });
  }
}

const deploymentApiClient = new DeploymentApiClient(
  undefined,
  'https://deployment.wavely.fr',
  axios,
);

export default deploymentApiClient;

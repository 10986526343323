import React, { Dispatch, SetStateAction } from 'react';

import moment from 'moment';

import {
  DateRange,
  DateRangeInput,
  TimePrecision,
} from '@blueprintjs/datetime';

import { ItemRenderer, MultiSelect } from '@blueprintjs/select';

import { Card, Divider, H5, HTMLSelect, MenuItem } from '@blueprintjs/core';

import { Campaign } from '@wavely/deployment-sdk';
import { RecordingType } from '../types/models';

interface SettingsCardProps {
  campaigns: Campaign[];
  currentCampaign?: Campaign;
  setCurrentCampaign: (campaign?: Campaign) => void;

  setStartDate: (date?: Date) => void;
  startDate?: Date;

  setEndDate: (date?: Date) => void;
  endDate?: Date;

  setRecordingTypes: Dispatch<SetStateAction<RecordingType[]>>;
  recordingTypes: RecordingType[];
}

const SettingsCard = (props: SettingsCardProps) => {
  const handleRangeChange = ([startDate, endDate]: DateRange) => {
    props.setStartDate(startDate);
    props.setEndDate(endDate);
  };

  const handleCampaignChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = event => {
    const selectedId = parseInt(event.currentTarget.value, 10);
    props.setCurrentCampaign(props.campaigns.find(c => c.id === selectedId));
  };

  const renderItem: ItemRenderer<RecordingType> = (
    recordingType,
    { modifiers, handleClick },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    if (props.recordingTypes.includes(recordingType)) {
      return null;
    }
    return (
      <MenuItem
        text={recordingType}
        onClick={handleClick}
        key={recordingType}
      />
    );
  };

  const handleItemSelect = (recordingType: RecordingType) => {
    props.setRecordingTypes(types => [...types, recordingType]);
  };

  const handleTagRemove = (_: string, index: number) => {
    props.setRecordingTypes(types => types.filter((_, i) => i !== index));
  };

  return (
    <Card className="settings-card">
      <div>
        <H5>Campaign</H5>
        <HTMLSelect
          value={props.currentCampaign ? props.currentCampaign.id : -1}
          onChange={handleCampaignChange}
        >
          <option disabled={true} value={-1} />
          {props.campaigns.map(c => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </HTMLSelect>
      </div>
      <Divider />
      <div>
        <H5>Datetime range</H5>
        <DateRangeInput
          formatDate={date => moment(date).calendar()}
          parseDate={str => new Date(str)}
          onChange={handleRangeChange}
          value={[props.startDate, props.endDate]}
          timePrecision={TimePrecision.MINUTE}
          timePickerProps={{ showArrowButtons: true }}
          allowSingleDayRange={true}
          maxDate={new Date()}
        />
      </div>
      <Divider />
      <div>
        <H5>Recording type(s)</H5>
        <MultiSelect
          items={[RecordingType.EVENT_DETECTION, RecordingType.PERIODIC]}
          itemRenderer={renderItem}
          onItemSelect={handleItemSelect}
          selectedItems={props.recordingTypes}
          tagRenderer={(recordingType: RecordingType) => recordingType}
          tagInputProps={{
            onRemove: handleTagRemove,
            tagProps: { minimal: true },
          }}
          fill={true}
          noResults={<MenuItem disabled={true} text="Nothing to add." />}
        />
      </div>
    </Card>
  );
};

export default SettingsCard;

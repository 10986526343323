import React from 'react';

import { Campaign, Location } from '@wavely/deployment-sdk';
import { RecordingType } from './types/models';

import './App.scss';

import deploymentApiClient from './utils/api';
import SettingsCard from './components/SettingsCard';
import TimeSeriesCard from './components/TimeSeriesCardProps';
import useAuth from './effects/useAuth';

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);
  const [currentCampaign, setCurrentCampaign] = React.useState<Campaign>();
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [startDate, setStartDate] = React.useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = React.useState<Date | undefined>(undefined);
  const [recordingTypes, setRecordingTypes] = React.useState<RecordingType[]>(
    [],
  );

  React.useEffect(() => {
    isAuthenticated &&
      deploymentApiClient.listCampaigns().then(res => {
        setCampaigns(res.data);
        res.data.length > 0 && setCurrentCampaign(res.data[0]);
      });
  }, [setCampaigns, isAuthenticated]);

  React.useEffect(() => {
    if (currentCampaign) {
      deploymentApiClient
        .listLocations(currentCampaign.id)
        .then(res => setLocations(res.data));
    }
  }, [currentCampaign, setLocations, isAuthenticated]);

  const renderSeriesSection = (location: Location) => {
    const site = currentCampaign!.sites.find(s => s.id === location.site);
    const area = site && site.areas.find(a => a.id === location.area);

    return (
      <TimeSeriesCard
        key={location.id}
        location={location}
        site={site}
        area={area}
        startDate={startDate}
        endDate={endDate}
        recordingTypes={recordingTypes}
      />
    );
  };

  if (!isAuthenticated) {
    return <div className="app" />;
  }

  return (
    <div className="app">
      <SettingsCard
        campaigns={campaigns}
        setCurrentCampaign={setCurrentCampaign}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        endDate={endDate}
        startDate={startDate}
        currentCampaign={currentCampaign}
        setRecordingTypes={setRecordingTypes}
        recordingTypes={recordingTypes}
      />
      <div className="time-series-section">
        {locations.filter(l => l.isActive).map(l => renderSeriesSection(l))}
      </div>
    </div>
  );
};

export default App;

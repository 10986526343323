import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Navbar } from '@blueprintjs/core';

import AuthProvider from './components/AuthProvider';
import Auth from './components/Auth';

ReactDOM.render(
  <AuthProvider>
    <Navbar>
      <Navbar.Group align="left">
        <Navbar.Heading>Soundbooth</Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align="right">
        <Auth />
      </Navbar.Group>
    </Navbar>
    <App />
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { Credentials } from '@wavely/deployment-sdk';

import deploymentApiClient from '../utils/api';
import {
  AuthContext,
  login as loginDispatch,
  error,
} from '../components/AuthProvider';

const useAuth = () => {
  const [state, dispatch] = React.useContext(AuthContext);

  const login = (credentials: Credentials) => {
    return deploymentApiClient
      .login(credentials)
      .then(res => {
        dispatch(
          loginDispatch({ username: credentials.username, ...res.data }),
        );
      })
      .catch(err => {
        if (err.response) {
          dispatch(error({ error: err.response.data.detail }));
        }
      });
  };

  return {
    isAuthenticated: state.isAuthenticated,
    username: state.username,
    access: state.access,
    error: state.error,
    login,
  };
};

export default useAuth;

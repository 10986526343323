import React from 'react';

interface AuthState {
  isAuthenticated: boolean;
  username: string;
  access: string;
  refresh: string;
  error: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  username: '',
  access: '',
  refresh: '',
  error: '',
};

const LOGIN = 'LOGIN';
interface Login {
  type: typeof LOGIN;
  username: string;
  access: string;
  refresh: string;
}
export const login = (data: {
  username: string;
  access: string;
  refresh: string;
}): Login => ({
  type: LOGIN,
  ...data,
});

const LOGOUT = 'LOGOUT';
interface Logout {
  type: typeof LOGOUT;
}

export const logout = (): Logout => ({ type: LOGOUT });

const ERROR = 'ERROR';
interface Error {
  type: typeof ERROR;
  error: string;
}
export const error = (data: { error: string }): Error => ({
  type: ERROR,
  ...data,
});

export type AuthAction = Login | Logout | Error;

const authReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case LOGIN:
      return {
        isAuthenticated: true,
        username: action.username,
        access: action.access,
        refresh: action.refresh,
        error: '',
      };
    case LOGOUT:
      return {
        isAuthenticated: false,
        username: '',
        access: '',
        refresh: '',
        error: '',
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

interface AuthProviderProps {
  children?: React.ReactNode;
}
export const AuthContext = React.createContext<
  [
    React.ReducerState<typeof authReducer>,
    React.Dispatch<React.ReducerAction<typeof authReducer>>,
  ]
>(null!);

const AuthProvider = (props: AuthProviderProps) => {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  // TODO(vmatt): Use localStorage

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

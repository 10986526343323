import React from 'react';

import deploymentApiClient from '../utils/api';
import axios from '../utils/axios';
import moment from 'moment';

import {
  AnchorButton,
  Button,
  Card,
  H5,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Tag,
  Text,
  Tooltip,
} from '@blueprintjs/core';

import VirtualizedTable from './VirtualizedTable';
import Chart from './Chart';

import { Area, Deployment, Location, Site } from '@wavely/deployment-sdk';
import { Metadatum, RecordingType } from '../types/models';

interface TimeSeriesCardProps {
  site?: Site;
  area?: Area;
  location: Location;
  startDate?: Date;
  endDate?: Date;
  recordingTypes: RecordingType[];
}

enum Status {
  LOADING,
  SUCCESS,
  ERROR,
}

const TimeSeriesCard = (props: TimeSeriesCardProps) => {
  const [status, setStatus] = React.useState<Status>(Status.LOADING);

  const [deployments, setDeployments] = React.useState<Deployment[]>([]);
  const [metadata, setMetadata] = React.useState<Metadatum[]>([]);

  React.useEffect(() => {
    deploymentApiClient
      .listDeployments(undefined, props.location.id)
      .then(res => {
        setDeployments(res.data);

        if (res.data.length > 0) {
          const activeDeployment = res.data[0];
          axios
            .get<Metadatum[]>(
              `/api/magnetos/${activeDeployment.sensorId}/metadata/`,
            )
            .then(res => {
              setStatus(Status.SUCCESS);
              setMetadata(res.data);
            })
            .catch(error => {
              if (error.response) {
                switch (error.response.status) {
                  case 503:
                  case 504:
                    setStatus(Status.ERROR);
                    break;
                  default:
                    break;
                }
              }
            });
        }
      });
  }, [setDeployments, setMetadata, props.location.id, setStatus]);

  let timeRangeEvents = metadata.filter(e => {
    const startTime = new Date(e.startTime).getTime();
    return (
      (!props.startDate || startTime >= props.startDate.getTime()) &&
      (!props.endDate || startTime <= props.endDate.getTime())
    );
  });

  if (props.recordingTypes.length > 0) {
    timeRangeEvents = timeRangeEvents.filter(e =>
      props.recordingTypes.includes(e.recordingType),
    );
  }

  const timeRangeDeployments = deployments.filter(d => {
    // TODO(vmttn): check that there is an actual startDate
    const startDate = new Date(d.startDate!).getTime();
    const endDate = d.endDate
      ? new Date(d.endDate).getTime()
      : new Date().getTime();
    return (
      (!props.startDate || endDate >= props.startDate.getTime()) &&
      (!props.endDate || startDate <= props.endDate.getTime())
    );
  });

  return (
    <Card
      key={props.location.id}
      className={`time-series-card ${status === Status.ERROR ? 'error' : ''}`}
      interactive={true}
    >
      <div className="card-header">
        <H5>{props.location.name}</H5>
        <div className="tags-group">
          {props.site && (
            <Tag icon="map" minimal={true}>
              {props.site.name}
            </Tag>
          )}
          {props.area && (
            <Tag icon="polygon-filter" minimal={true}>
              {props.area.name}
            </Tag>
          )}
          {status === Status.ERROR && (
            <Tooltip
              content={`${deployments[0].sensorType.toLowerCase()} ${
                deployments[0].sensorId
              } currently unreachable`}
              intent={Intent.DANGER}
            >
              <Icon icon="offline" intent={Intent.DANGER} />
            </Tooltip>
          )}
        </div>
        <Text>{timeRangeDeployments.length} deployments</Text>
        <Text>{timeRangeEvents.length} events</Text>
        <Popover interactionKind={PopoverInteractionKind.HOVER}>
          <Button icon="list" intent={Intent.PRIMARY}>
            List
          </Button>
          <div className="event-table">
            <VirtualizedTable
              rows={timeRangeEvents}
              renderHeaders={() => (
                <tr>
                  <th>Sensor</th>
                  <th>Datetime</th>
                  <th />
                </tr>
              )}
              renderRow={row => (
                <tr key={row.id}>
                  <td>{row.sensor}</td>
                  <td>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {moment(row.startTime).calendar()}
                    </span>
                  </td>
                  <td>
                    <AnchorButton
                      icon="download"
                      href={`/api/magnetos/${row.sensor}/recordings/${row.id}`}
                      download={true}
                    />
                  </td>
                </tr>
              )}
            />
          </div>
        </Popover>
      </div>
      <Chart
        location={props.location}
        deployments={deployments}
        metadata={timeRangeEvents}
        startDate={props.startDate}
        endDate={props.endDate}
      />
    </Card>
  );
};

export default TimeSeriesCard;
